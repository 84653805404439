"use client";
import React from "react";
import { AiOutlineExport } from "react-icons/ai";
import Link from "next/link";
export default function RentalSection() {
    return (
        <div className="rental-section">
            <h2>Floor Scrubber Rentals</h2>
            <p>
                Let us help you find the right floor scrubber for your needs.
            </p>
            <Link href="/floor-scrubber-rental">I Need a Rental</Link>
        </div>
    );
}
